
import { defineComponent, onMounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import CategoryResource from '@/resources/CategoryResource';
import Form from '@/components/Category/Form.vue';
import useForm from '@/composables/use-form';

export default defineComponent({
    components: { Form },

    setup() {
        const route = useRoute();
        const router = useRouter();
        const category = reactive({});
        const form = useForm(category, CategoryResource);
        const { isSaving, update  } = form;

        onMounted(() => {
            CategoryResource.get({id: Number(route.params.id)}).then((response) => {
                Object.assign(category, response);
            });
        });

        function submit() {
            update().then(() => {
                router.go(-1);
            });
        }

        return {
            category,
            form,
            isSaving,
            submit,
        };
    },
});
