import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "field is-grouped is-grouped-right" }
const _hoisted_3 = { class: "control" }
const _hoisted_4 = { class: "control" }
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.category.name), 1),
    _createVNode(_component_Form, { form: _ctx.form }, null, 8, ["form"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, [
        _createElementVNode("a", {
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$router.go(-1)), ["prevent"])),
          class: "button is-light"
        }, _toDisplayString(_ctx.$t('general.BACK')), 1)
      ]),
      _createElementVNode("p", _hoisted_4, [
        _createElementVNode("button", {
          class: "button is-primary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (!_ctx.isSaving && _ctx.submit())),
          disabled: _ctx.isSaving
        }, _toDisplayString(_ctx.$t('general.UPDATE')), 9, _hoisted_5)
      ])
    ])
  ]))
}